<template>
  <div class="content">
    <div class="top">
      <a-select
          allowClear
          showSearch
          class="w-200 mb-5"
          v-model="params.type"
          placeholder="拉黑类型"
      >
        <a-select-option
            v-for="item of blackrelList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-input
          allowClear
          v-model="params.userId"
          class="w-200 mb-5 ml-5"
          placeholder='发起用户userId'
      ></a-input>
      <a-input
          allowClear
          v-model="params.targetUserId"
          class="w-200 mb-5 ml-5"
          placeholder='目标用户userId'
      ></a-input>
      <a-button
          class="mb-5 ml-5"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          class="ml-20 mb-5"
          @click="handleAddUserBlackLink"
      >新增拉黑关系</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @addSuccess="getList"
      />
    </div>
    <!--  搜索词配置新增  -->
    <EditorUserBlackLinkPopup ref="editorSearchWordConfigPopupEl" @addSuccess="getList" />
  </div>
</template>
<script>
import {BLACKREL_TYPE_LIST} from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_data"
import {getUserBlackLinkListApi} from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_apis"
import EditorUserBlackLinkPopup
  from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_components/EditorUserBlackLinkPopup/index.vue"
import TableList from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_components/TableList/index.vue"

export default {
  components: {
    TableList, EditorUserBlackLinkPopup,
  },
  data() {
    return {
      data: [],
      blackrelList: BLACKREL_TYPE_LIST,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        type: undefined,
        userId: undefined,
        targetUserId: undefined
      },
    };
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 新增配置 */
    handleAddUserBlackLink() {
      this.$refs.editorSearchWordConfigPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getUserBlackLinkListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
