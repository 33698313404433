var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { centered: "", width: 650, title: "添加拉黑关系" },
      on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.forms } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "拉黑发起用户", prop: "content" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        closable: "",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "搜索发起用户昵称或userId"
                      },
                      on: {
                        search: function($event) {
                          return _vm.searchUserInfo($event, "userId")
                        },
                        change: _vm.handleChangeSelectedUserInfo
                      },
                      model: {
                        value: _vm.forms.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "userId", $$v)
                        },
                        expression: "forms.userId"
                      }
                    },
                    _vm._l(_vm.userInfoList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.userId } },
                        [
                          _vm._v(
                            _vm._s(item.userName + "【" + item.userId + "】")
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拉黑目标用户", prop: "targetUserId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        closable: "",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "搜索目标用户昵称或userId"
                      },
                      on: {
                        search: function($event) {
                          return _vm.searchUserInfo($event, "targetUserId")
                        },
                        change: _vm.handleChangeSelectedUserInfo
                      },
                      model: {
                        value: _vm.forms.targetUserId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "targetUserId", $$v)
                        },
                        expression: "forms.targetUserId"
                      }
                    },
                    _vm._l(_vm.targetUserInfoList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.userId } },
                        [
                          _vm._v(
                            _vm._s(item.userName + "【" + item.userId + "】")
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              false
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "拉黑时间", prop: "timeSecond" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            closable: "",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "选择拉黑的时长"
                          },
                          model: {
                            value: _vm.forms.timeSecond,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "timeSecond", $$v)
                            },
                            expression: "forms.timeSecond"
                          }
                        },
                        _vm._l(_vm.blackLinkTimeList, function(item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注描述", prop: "remark" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "备注描述", rows: 4 },
                    model: {
                      value: _vm.forms.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "remark", $$v)
                      },
                      expression: "forms.remark"
                    }
                  }),
                  _c("div", { staticClass: "color-red" }, [
                    _vm._v(
                      "tips：这里的拉黑涉及到 点对点的IM私信互相拉黑, ta们的群聊都禁言"
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }