import {objArrMapObj} from "@/untils/otherEvent"

/** 拉黑关系列表 */
export const blackLinkColumns = [
    { title: "ID", align: "center", dataIndex: 'id', width: 100 },
    { title: "拉黑发起用户", align: "center", scopedSlots: { customRender: "itemUserInfoSlot" }, width: 250 },
    { title: "拉黑目标用户", align: "center", scopedSlots: { customRender: "itemTargetUserInfoSlot" }, width: 250 },
    { title: "拉黑类型", align: "center", scopedSlots: { customRender: "itemBlackLinkTypeSlot" }, width: 100 },
    { title: "备注", align: "center", dataIndex: 'remark', width: 150 },
    { title: "创建时间", align: "center", dataIndex: 'createTime', width: 150 },
    { title: "结束时间", align: "center", dataIndex: 'endTime', width: 150 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]


/** 拉黑类型  0用户拉黑  1平台拉黑*/
export const BLACKREL_TYPE_LIST = [
    { name: '点对点拉黑', value: 0 },
    { name: '平台拉黑', value: 1 },
]

/** 拉黑类型对应样式类 */
export const BLACKREL_TYPE_CLASS_TYPE = {
    0: 'color-purple',
    1: 'color-red'
}

export const blackLinkTypeMapTex = (value) => {
    return objArrMapObj('value', 'name', BLACKREL_TYPE_LIST)[value]
}