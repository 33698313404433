<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  拉黑发起用户    -->
      <div slot="itemUserInfoSlot" slot-scope="row">
        <div v-if="row.userName">{{ row.userName }} <span class="color-green">【{{ row.userId }}】</span></div>
        <div v-else>-</div>
      </div>
<!--  拉黑目标用户    -->
      <div slot="itemTargetUserInfoSlot" slot-scope="row">
        <div>{{ row.targetUserName }} <span class="color-green">【{{ row.targetUserId }}】</span></div>
      </div>
<!--  拉黑类型    -->
      <div slot="itemBlackLinkTypeSlot" slot-scope="row">
        <div :class="blackLinkTypeClassType[row.type]">{{ computedBlackLinkTypeMapTex(row.type) }}</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-popconfirm
            title="取消拉黑 点对点私聊恢复,群聊恢复？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleCancelBlackLink(row)"
        >
          <a-button v-if="row.type === 0" size="small" type="danger">取消拉黑关系</a-button>
        </a-popconfirm>
        <a-button v-if="row.type === 1" size="small" type="link" @click="handleGoToUserManage(row)">前往用户管理取消平台拉黑</a-button>
      </div>
    </a-table>
    <!--  展示物流信息  -->
    <LogisticsInfoPopup ref="logisticsInfoPopupEl"/>
    <!--  展示D类送评图片列表  -->
    <ShowMoreImages ref="showMoreImagesEl"/>
  </div>
</template>
<script>
import LogisticsInfoPopup from "@/views/cmsPage/checkManage/recordlist/_components/LogisticsInfoPopup"
import ShowMoreImages from "@/views/cmsPage/checkManage/operate/_components/ShowMoreImages"
import {
  blackLinkColumns,
  blackLinkTypeMapTex,
  BLACKREL_TYPE_CLASS_TYPE
} from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_data"
import {cancelUserBlackLinkApi} from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_apis"

export default {
  props: ['pagination', 'list'],
  components: {LogisticsInfoPopup, ShowMoreImages},
  data() {
    return {
      tableList: blackLinkColumns,
      blackLinkTypeClassType: BLACKREL_TYPE_CLASS_TYPE
    }
  },
  computed: {
    computedBlackLinkTypeMapTex() {
      return (value) => blackLinkTypeMapTex(value)
    }
  },
  methods: {
    /** 去往用户管理 */
    handleGoToUserManage(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/usermannage?userId=${row.targetUserId}`;
      window.open(url)
    },
    /** 取消拉黑 */
    async handleCancelBlackLink(row) {
      this.$loading.show()
      const res = await cancelUserBlackLinkApi(row.id)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message || '取消成功')
      this.$emit('addSuccess')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  color: green;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>