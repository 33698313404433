import instance from "@/axios"

/** 搜索词配置列表 */
export const getUserBlackLinkListApi = async (params) => {
    return instance({
        url: '/dq_admin/userBlockedUserRel/list',
        method: 'GET',
        params: params
    })
}

/** 添加拉黑关系 */
export const postUserBlackLinkApi = async (params) => {
    return instance({
        url: '/dq_admin/userBlockedUserRel/forbidImMsg',
        method: 'POST',
        data: params
    })
}

/** 添加拉黑关系 */
export const cancelUserBlackLinkApi = async (params) => {
    return instance({
        url: '/dq_admin/userBlockedUserRel/unForbidImMsg',
        method: 'get',
        params: {
            id: params
        }
    })
}