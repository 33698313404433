var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemUserInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.userName
                  ? _c("div", [
                      _vm._v(_vm._s(row.userName) + " "),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v("【" + _vm._s(row.userId) + "】")
                      ])
                    ])
                  : _c("div", [_vm._v("-")])
              ])
            }
          },
          {
            key: "itemTargetUserInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [
                  _vm._v(_vm._s(row.targetUserName) + " "),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v("【" + _vm._s(row.targetUserId) + "】")
                  ])
                ])
              ])
            }
          },
          {
            key: "itemBlackLinkTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { class: _vm.blackLinkTypeClassType[row.type] }, [
                  _vm._v(_vm._s(_vm.computedBlackLinkTypeMapTex(row.type)))
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "取消拉黑 点对点私聊恢复,群聊恢复？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleCancelBlackLink(row)
                        }
                      }
                    },
                    [
                      row.type === 0
                        ? _c(
                            "a-button",
                            { attrs: { size: "small", type: "danger" } },
                            [_vm._v("取消拉黑关系")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  row.type === 1
                    ? _c(
                        "a-button",
                        {
                          attrs: { size: "small", type: "link" },
                          on: {
                            click: function($event) {
                              return _vm.handleGoToUserManage(row)
                            }
                          }
                        },
                        [_vm._v("前往用户管理取消平台拉黑")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c("LogisticsInfoPopup", { ref: "logisticsInfoPopupEl" }),
      _c("ShowMoreImages", { ref: "showMoreImagesEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }