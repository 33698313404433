<template>
  <a-modal
      centered
      :width='650'
      title="添加拉黑关系"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms'>
        <a-form-model-item label='拉黑发起用户' prop='content'>
          <a-select
              @search="searchUserInfo($event, 'userId')"
              v-model="forms.userId"
              closable
              :filter-option="untils.filterOption"
              @change="handleChangeSelectedUserInfo"
              showSearch
              placeholder="搜索发起用户昵称或userId"
          >
            <a-select-option
                v-for="(item, index) of userInfoList"
                :key="index"
                :value="item.userId"
            >{{ item.userName + `【${item.userId}】` }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='拉黑目标用户' prop='targetUserId'>
          <a-select
              @search="searchUserInfo($event, 'targetUserId')"
              v-model="forms.targetUserId"
              closable
              :filter-option="untils.filterOption"
              @change="handleChangeSelectedUserInfo"
              showSearch
              placeholder="搜索目标用户昵称或userId"
          >
            <a-select-option
                v-for="(item, index) of targetUserInfoList"
                :key="index"
                :value="item.userId"
            >{{ item.userName + `【${item.userId}】` }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="false" label='拉黑时间' prop='timeSecond'>
          <a-select
              v-model="forms.timeSecond"
              closable
              :filter-option="untils.filterOption"
              showSearch
              placeholder="选择拉黑的时长"
          >
            <a-select-option
                v-for="(item, index) of blackLinkTimeList"
                :key="index"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='备注描述' prop='remark'>
          <a-textarea
              v-model="forms.remark"
              placeholder="备注描述"
              :rows="4" />
          <div class="color-red">tips：这里的拉黑涉及到 点对点的IM私信互相拉黑, ta们的群聊都禁言</div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {
  getUserInfoByUserIdOrUserNameApi
} from "@/axios/apis"
import {postUserBlackLinkApi} from "@/views/cmsPage/dqPlatform/userBlackLinkManage/_apis"
import {BLACK_LINK_TIME_LIST} from "@/views/cmsPage/dqPlatform/_data"
export default {
  data() {
    return {
      untils,
      showModal: false,
      userInfoList: [],
      targetUserInfoList: [],
      blackLinkTimeList: BLACK_LINK_TIME_LIST,
      forms: {
        type: 0,
        userId: undefined,
        targetUserId: undefined,
        remark: undefined,
        timeSecond: 31536000,
      },
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
    }
  },
  methods: {
    async show() {
      this.showModal = true
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.forms.userId) return this.$message.warn('请选择发起用户')
      if (!this.forms.targetUserId) return this.$message.warn('请选择目标用户')
      if (!this.forms.remark) return this.$message.warn('请输入拉黑原因备注')
      this.$loading.show()
      const res = await postUserBlackLinkApi(this.forms)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.$emit('addSuccess')
      this.handleCancel()
    },
    /** 重置表单 */
    handleCancel() {
      this.forms = {
        type: 0,
        userId: undefined,
        targetUserId: undefined,
        remark: undefined,
        timeSecond: 31536000,
      }
      this.showModal = false
    },
    /** 去修改用户的选择 */
    handleChangeSelectedUserInfo(e) {

    },
    /** 用户搜索 */
    async searchUserInfo(inner, type) {
      const res = await getUserInfoByUserIdOrUserNameApi(inner)
      if (res.status !== "200") return
      const { records } = res.data;
      if (type === 'userId') {
        this.userInfoList = records
      } else {
        this.targetUserInfoList = records
      }
    },
  }
}
</script>
<style scoped lang="scss">

</style>